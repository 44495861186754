import { mapGetters } from 'vuex'

export const mapCurrentUser = () => {
  return mapGetters('authentication', ['currentUser'])
}

export const mapXsrfToken = () => {
  return mapGetters('authentication', ['xsrfToken'])
}

export const mapUserPerms = () => {
  return mapGetters('authentication', ['userPermissions'])
}
