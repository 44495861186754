import Vue from 'vue'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import Notifications from './plugins/Notifications'
import Snom from './plugins/Snom'
import axios from 'axios'

import App from './App'
import router from './router'
import store from './store'
import * as VueGoogleMaps from 'vue2-google-maps'

// import Truck from './truck'

/**
 * Digest Axios
 */

/**
 * Laravel BroadCasting
 */
import Echo from 'laravel-echo';

window.Pusher = require('pusher-js');

/**
 * Google Maps
 */
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyA8oNNmTPCISwXy8EtXzBcwwcUJ7-knwYA',
    libraries: 'places',
  },
  installComponents: true
})

Vue.use(BootstrapVueIcons)
Vue.use(BootstrapVue)
Vue.use(Notifications)
Vue.use(Snom)

import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faAddressCard, faAngleLeft, faAngleRight, faArrowRight, faColumns, faFileInvoice,
  faFileInvoiceDollar, faHandSparkles, faHotel, faKey, faPen, faPlus, faPumpSoap,
  faRedo, faShippingFast, faStopwatch, faTasks, faTrash, faUmbrellaBeach, faUpload, faDownload,
  faUserPlus, faUsers, faTimes, faEnvelope, faPaperPlane, faFileImport, faFlag, faMapMarked, faCheck, faList,
  faBolt, faShoppingCart, faPhone, faTools, faHeadset, faCaretUp, faCaretDown, faSlidersH, faGlobe
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import {
  faCommentAltLines, faCommentsAlt
} from '@fortawesome/pro-solid-svg-icons'

library.add(faAddressCard);
library.add(faAngleLeft);
library.add(faAngleRight);
library.add(faArrowRight);
library.add(faColumns);
library.add(faHandSparkles);
library.add(faHotel);
library.add(faFileInvoice);
library.add(faFileInvoiceDollar);
library.add(faKey);
library.add(faRedo);
library.add(faPen);
library.add(faPlus);
library.add(faPumpSoap);
library.add(faShippingFast);
library.add(faStopwatch);
library.add(faTasks);
library.add(faTrash);
library.add(faUmbrellaBeach);
library.add(faUpload);
library.add(faUsers);
library.add(faUserPlus);
library.add(faTimes);
library.add(faDownload);
library.add(faEnvelope);
library.add(faPaperPlane);
library.add(faFileImport);
library.add(faFlag);
library.add(faMapMarked);
library.add(faCheck);
library.add(faColumns);
library.add(faList);
library.add(faBolt);
library.add(faShoppingCart);
library.add(faCommentAltLines);
library.add(faPhone);
library.add(faTools);
library.add(faHeadset);
library.add(faCommentsAlt);
library.add(faCaretUp);
library.add(faCaretDown);
library.add(faSlidersH);
library.add(faGlobe);

Vue.component('fa-icon', FontAwesomeIcon)

window.axios = axios
window.axios.defaults.baseURL = process.env.VUE_APP_API_URL
window.axios.defaults.headers.Accept = 'application/json'
window.axios.defaults.headers['X-Requested-With'] = 'XMLHttpRequest'
window.axios.defaults.withCredentials = true

Vue.config.productionTip = false

window.Echo = new Echo({
    broadcaster: 'pusher',
    key: process.env.VUE_APP_PUSHER_APP_KEY,
    cluster: process.env.VUE_APP_PUSHER_APP_CLUSTER,
    forceTLS: true
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
