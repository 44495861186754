import Vue from 'vue'
import VueRouter from 'vue-router'
import includes from 'lodash/includes'
import Container from '../views/Dashboard'
import permissions from '../permissions.json'
import store from '../store'
import { normalizeUnits } from 'moment'

Vue.use(VueRouter)

const routes = [
  {
    name: 'pay',
    path: '/pay/:uuid',
    meta: { auth: false, title: 'Pay Invoice' },
    component: () => import(/* webpackChunkName: "pay" */ '../views/Invoices/Pay.vue')
  }, {
    path: '/',
    component: Container,
    children: [
      {
        path: '',
        meta: { auth: true, can: permissions.CAN_VIEW_OVERVIEW },
        component: () => import(/* webpackChunkName: "users" */ '../views/Overview/Wrapper.vue'),
        children: [
          {
            path: '',
            name: 'dash.index',
            component: () => import(/* webpackChunkName: "users" */ '../views/Overview/Overview.vue'),
            meta: { auth: true, routeIdentifier: 'overview', can: permissions.CAN_VIEW_OVERVIEW, title: 'Overview' },
          }
        ],
      }, {
        path: 'calls',
        component: () => import(/* webpackChunkName: "customers" */ '../views/Customers/Wrapper.vue'),
        children: [
          {
            path: '',
            name: 'dash.calls',
            meta: { auth: true, routeIdentifier: 'calls', can: permissions.CAN_VIEW_CALLS, title: 'Calls' },
            component: () => import(/* webpackChunkName: "customers" */ '../views/Customers/Calls.vue')
          }, {
            path: 'scheduled',
            name: 'dash.calls.scheduled',
            meta: { auth: true, routeIdentifier: 'scheduled-calls', can: permissions.CAN_VIEW_CALLS, title: 'Scheduled Calls' },
            component: () => import(/* webpackChunkName: "customers" */ '../views/Customers/ScheduledCalls.vue')
          }, {
            path: 'create',
            name: 'dash.calls.scheduled.create',
            meta: { auth: true, routeIdentifier: 'scheduled-calls', can: permissions.CAN_VIEW_CALLS, title: 'Scheduled Calls' },
            component: () => import(/* webpackChunkName: "customers" */ '../views/Customers/ScheduledCallUpsert.vue')
          }, {
            path: '/scheduled/view/:id',
            name: 'dash.calls.scheduled.view',
            meta: { auth: true, routeIdentifier: 'scheduled-calls', can: permissions.CAN_VIEW_CALLS, title: 'Scheduled Calls' },
            component: () => import(/* webpackChunkName: "customers" */ '../views/Customers/ScheduledCallView.vue')
          }, {
            path: 'missed',
            name: 'dash.calls.missed',
            meta: { auth: true, routeIdentifier: 'missed-calls', can: permissions.CAN_VIEW_CALLS, title: 'Missed Calls' },
            component: () => import(/* webpackChunkName: "customers" */ '../views/Customers/MissedCalls.vue')
          }
        ]
      }, {
        path: 'tickets',
        component: () => import(/* webpackChunkName: "tickets" */ '../views/Tickets/Wrapper.vue'),
        children: [
          {
            path: '',
            name: 'dash.tickets.list',
            meta: { auth: true, routeIdentifier: 'support', can: permissions.CAN_VIEW_TICKETS, title: 'Tickets' },
            component: () => import(/* webpackChunkName: "tickets" */ '../views/Tickets/List.vue')
          },{
            path: 'create',
            name: 'dash.tickets.create',
            meta: { auth: true, routeIdentifier: 'support', can: permissions.CAN_VIEW_TICKETS, title: 'Create Ticket' },
            component: () => import(/* webpackChunkName: "tickets" */ '../views/Tickets/Upsert.vue')
          }, {
            path: 'view/:id',
            name: 'dash.tickets.view',
            meta: { auth: true, routeIdentifier: 'support', can: permissions.CAN_VIEW_TICKETS, title: 'View Ticket' },
            component: () => import(/* webpackChunkName: "tickets" */ '../views/Tickets/TicketView.vue')
          }, {
            path: 'book-repair/:id',
            name: 'dash.tickets.book_repair',
            meta: { auth: true, routeIdentifier: 'repairs', can: permissions.CAN_VIEW_TICKETS, title: 'Book Repair' },
            component: () => import(/* webpackChunkName: "tickets" */ '../views/Tickets/BookRepair.vue')
          }, {
            path: ':id',
            name: 'dash.tickets.update',
            meta: { auth: true, routeIdentifier: 'support', can: permissions.CAN_VIEW_TICKETS, title: 'Update Ticket' },
            component: () => import(/* webpackChunkName: "tickets" */ '../views/Tickets/Upsert.vue')
          }
        ]
      }, {
        path: 'repairs',
        component: () => import(/* webpackChunkName: "tickets" */ '../views/Repairs/Wrapper.vue'),
        children: [
          {
            path: '',
            name: 'dash.repairs.list',
            meta: { auth: true, routeIdentifier: 'repairs', can: permissions.CAN_VIEW_REPAIRS, title: 'Repairs' },
            component: () => import(/* webpackChunkName: "tickets" */ '../views/Repairs/List.vue')
          }, {
            path: 'calendar',
            name: 'dash.repairs.calendar',
            meta: { auth: true, routeIdentifier: 'repair-calendar', can: permissions.CAN_VIEW_REPAIRS, title: 'Repair Calendar' },
            component: () => import(/* webpackChunkName: "tickets" */ '../views/Repairs/RepairCalendar.vue')
          }, {
            path: ':id',
            name: 'dash.repairs.complete',
            meta: { auth: true, routeIdentifier: 'repairs', can: permissions.CAN_VIEW_REPAIRS, title: 'Repairs' },
            component: () => import(/* webpackChunkName: "tickets" */ '../views/Repairs/CompleteRepair.vue')
          }, {
            path: 'view/:id',
            name: 'dash.repairs.view',
            meta: { auth: true, routeIdentifier: 'repairs', can: permissions.CAN_VIEW_REPAIRS, title: 'Repairs' },
            component: () => import(/* webpackChunkName: "tickets" */ '../views/Repairs/RepairView.vue')
          }, 
        ]
      }, {
        path: 'emails',
        component: () => import(/* webpackChunkName: "tickets" */ '../views/Emails/Wrapper.vue'),
        children: [
          {
            path: '/',
            name: 'dash.emails.list',
            meta: { auth: true, routeIdentifier: 'emails', can: permissions.CAN_VIEW_CUSTOMERS, title: 'Email Feed' },
            component: () => import(/* webpackChunkName: "tickets" */ '../views/Emails/List.vue')
          },
          {
            path: '/compose',
            name: 'dash.emails.compose',
            meta: { auth: true, routeIdentifier: 'emails', can: permissions.CAN_VIEW_CUSTOMERS, title: 'Compose Email' },
            component: () => import(/* webpackChunkName: "tickets" */ '../views/Emails/Compose.vue')
          },
        ]
      }, {
        path: 'templates',
        component: () => import(/* webpackChunkName: "tickets" */ '../views/Templates/Wrapper.vue'),
        children: [
          {
            path: '/',
            name: 'dash.templates.list',
            meta: { auth: true, routeIdentifier: 'templates', can: permissions.CAN_VIEW_CUSTOMERS, title: 'Templates' },
            component: () => import(/* webpackChunkName: "tickets" */ '../views/Templates/List.vue')
          }, {
            path: '/create',
            name: 'dash.templates.create',
            meta: { auth: true, routeIdentifier: 'templates', can: permissions.CAN_VIEW_CUSTOMERS, title: 'Create Template' },
            component: () => import(/* webpackChunkName: "tickets" */ '../views/Templates/Upsert.vue')
          }, {
            path: 'templates/:id',
            name: 'dash.templates.update',
            meta: { auth: true, routeIdentifier: 'templates', can: permissions.CAN_VIEW_CUSTOMERS, title: 'Edit Template' },
            component: () => import(/* webpackChunkName: "tickets" */ '../views/Templates/Upsert.vue')
          },
        ]
      }, {
        path: 'teams',
        component: () => import(/* webpackChunkName: "teams" */ '../views/Teams/Wrapper.vue'),
        children: [
          {
            path: '',
            name: 'dash.teams.list',
            meta: { auth: true, routeIdentifier: 'teams', can: permissions.CAN_VIEW_TEAMS, title: 'Teams' },
            component: () => import(/* webpackChunkName: "teams" */ '../views/Teams/List.vue')
          }, {
            path: 'create',
            name: 'dash.teams.create',
            meta: { auth: true, routeIdentifier: 'teams', can: permissions.CAN_VIEW_TEAMS, title: 'Create Team' },
            component: () => import(/* webpackChunkName: "teams" */ '../views/Teams/Upsert.vue')
          }, {
            path: ':id',
            name: 'dash.teams.update',
            meta: { auth: true, routeIdentifier: 'teams', can: permissions.CAN_VIEW_TEAMS, title: 'Update Team' },
            component: () => import(/* webpackChunkName: "teams" */ '../views/Teams/Upsert.vue')
          }
        ]
      }, {
        path: 'customers',
        component: () => import(/* webpackChunkName: "customers" */ '../views/Customers/Wrapper.vue'),
        children: [
          {
            path: '',
            name: 'dash.customers.list',
            meta: {
              auth: true,
              can: permissions.CAN_VIEW_CUSTOMERS,
              routeIdentifier: 'customers',
              title: 'Customers'
            },
            component: () => import(/* webpackChunkName: "customers" */ '../views/Customers/List.vue')
          }, {
            path: 'mine',
            name: 'dash.customers.list.mine',
            meta: {
              auth: true,
              can: permissions.CAN_VIEW_CUSTOMERS,
              routeIdentifier: 'my-customers',
              title: 'My Customers'
            },
            component: () => import(/* webpackChunkName: "customers" */ '../views/Customers/MyCustomers.vue')
          }, {
            path: 'unassigned',
            name: 'dash.customers.list.unassigned',
            meta: {
              auth: true,
              can: permissions.CAN_VIEW_CUSTOMERS,
              routeIdentifier: 'unassigned-customers',
              title: 'Customers'
            },
            component: () => import(/* webpackChunkName: "customers" */ '../views/Customers/UnassignedList.vue')
          }, {
            path: 'create',
            name: 'dash.customers.create',
            meta: { auth: true, routeIdentifier: 'customers', can: permissions.CAN_VIEW_CUSTOMERS, title: 'Create Customer' },
            component: () => import(/* webpackChunkName: "customers" */ '../views/Customers/Upsert.vue')
          }, {
            path: 'view/:id',
            name: 'dash.customers.view',
            meta: { auth: true, routeIdentifier: 'customers', can: permissions.CAN_VIEW_CUSTOMERS, title: 'View Customer' },
            component: () => import(/* webpackChunkName: "containers" */ '../views/Customers/CustomerView.vue')
          }, {
            path: ':id',
            name: 'dash.customers.update',
            meta: { auth: true, routeIdentifier: 'customers', can: permissions.CAN_VIEW_CUSTOMERS, title: 'Update Customer' },
            component: () => import(/* webpackChunkName: "customers" */ '../views/Customers/Upsert.vue')
          }, 
        ]
      }, {
        path: 'groups',
        component: () => import(/* webpackChunkName: "groups" */ '../views/Groups/Wrapper.vue'),
        children: [
          {
            path: '',
            name: 'dash.groups.list',
            meta: { auth: true, routeIdentifier: 'groups', can: permissions.CAN_VIEW_GROUPS, title: 'Groups' },
            component: () => import(/* webpackChunkName: "groups" */ '../views/Groups/List.vue')
          }, {
            path: 'create',
            name: 'dash.groups.create',
            meta: { auth: true, routeIdentifier: 'groups', can: permissions.CAN_VIEW_GROUPS, title: 'Create Group' },
            component: () => import(/* webpackChunkName: "groups" */ '../views/Groups/Upsert.vue')
          }, {
            path: ':id',
            name: 'dash.groups.update',
            meta: { auth: true, routeIdentifier: 'groups', can: permissions.CAN_VIEW_GROUPS, title: 'Update Group' },
            component: () => import(/* webpackChunkName: "groups" */ '../views/Groups/Upsert.vue')
          }
        ]
      }, {
        path: 'installations',
        component: () => import(/* webpackChunkName: "installations" */ '../views/Installations/Wrapper.vue'),
        children: [
          {
            path: '',
            name: 'dash.installations',
            meta: { auth: true, routeIdentifier: 'installations', can: permissions.CAN_VIEW_ASSIGNED_INSTALLATIONS, title: 'Installations' },
            component: () => import(/* webpackChunkName: "installations" */ '../views/Installations/List.vue')
          },
          {
            path: 'routes',
            name: 'dash.installations.routes',
            meta: { auth: true, routeIdentifier: 'installation-routes', can: permissions.CAN_VIEW_ASSIGNED_INSTALLATIONS, title: 'Installation Routes' },
            component: () => import(/* webpackChunkName: "orders" */ '../views/Installations/InstallationRoutes.vue')
          },
          {
            path: 'routes/pending',
            name: 'dash.installations.routes-pending',
            meta: { auth: true, routeIdentifier: 'installation-routes-pending', can: permissions.CAN_VIEW_ASSIGNED_INSTALLATIONS, title: 'Installation Routes' },
            component: () => import(/* webpackChunkName: "orders" */ '../views/Installations/InstallationRoutesPending.vue')
          },
          {
            path: 'calendar',
            name: 'dash.installations.calendar',
            meta: { auth: true, routeIdentifier: 'installation-calendar', can: permissions.CAN_VIEW_ASSIGNED_INSTALLATIONS, title: 'Installation Calendar' },
            component: () => import(/* webpackChunkName: "orders" */ '../views/Installations/InstallationCalendar.vue')
          }, 
          {
            path: 'sign-off/:id',
            name: 'dash.installations.sign-off',
            meta: { auth: true, routeIdentifier: 'installations', can: permissions.CAN_VIEW_ASSIGNED_INSTALLATIONS, title: 'Installation Sign Off' },
            component: () => import(/* webpackChunkName: "installations" */ '../views/Installations/SignOff.vue')
          },
          {
            path: 'sign-offs',
            name: 'dash.installations.sign-offs',
            meta: { auth: true, routeIdentifier: 'installation-sign-offs', can: permissions.CAN_VIEW_ALL_INSTALLATIONS, title: 'Installation Sign Off' },
            component: () => import(/* webpackChunkName: "installations" */ '../views/Installations/SignOffList.vue')
          },
          {
            path: 'manage',
            name: 'dash.installations.manage',
            meta: { auth: true, routeIdentifier: 'installation-manager', can: permissions.CAN_VIEW_ALL_INSTALLATIONS, title: 'Installations Manager' },
            component: () => import(/* webpackChunkName: "installations" */ '../views/Installations/Manager.vue')
          },
          {
            path: 'process/:id',
            name: 'dash.installations.process',
            meta: { auth: true, routeIdentifier: 'installation-manager', can: permissions.CAN_VIEW_ALL_INSTALLATIONS, title: 'Installations Manager' },
            component: () => import(/* webpackChunkName: "installations" */ '../views/Installations/Process.vue')
          },
          {
            path: 'view/:id',
            name: 'dash.installations.view',
            meta: { auth: true, routeIdentifier: 'installation-view', can: permissions.CAN_VIEW_ALL_INSTALLATIONS, title: 'Installation View' },
            component: () => import(/* webpackChunkName: "installations" */ '../views/Installations/View.vue')
          }
        ]
      }, {
        path: 'invoices',
        component: () => import(/* webpackChunkName: "invoices" */ '../views/Invoices/Wrapper.vue'),
        children: [
          {
            path: '',
            name: 'dash.invoices.list',
            meta: { auth: true, routeIdentifier: 'invoices', can: permissions.CAN_VIEW_INVOICES, title: 'Invoices' },
            component: () => import(/* webpackChunkName: "invoices" */ '../views/Invoices/List.vue')
          }, {
            path: 'recent',
            name: 'dash.invoices.recent',
            meta: { auth: true, routeIdentifier: 'recent-payments', can: permissions.CAN_VIEW_INVOICES, title: 'Recent Payments' },
            component: () => import(/* webpackChunkName: "invoices" */ '../views/Invoices/RecentPayments.vue')
          }, {
            path: 'soon',
            name: 'dash.invoices.soon',
            meta: { auth: true, routeIdentifier: 'delivering-soon', can: permissions.CAN_VIEW_INVOICES, title: 'Delivering Soon' },
            component: () => import(/* webpackChunkName: "invoices" */ '../views/Invoices/DeliveringSoon.vue')
          }, {
            path: 'create',
            name: 'dash.invoices.create',
            meta: { auth: true, routeIdentifier: 'invoices', can: permissions.CAN_VIEW_INVOICES, title: 'Create Invoice' },
            component: () => import(/* webpackChunkName: "invoices" */ '../views/Invoices/Upsert.vue')
          }, {
            path: ':id',
            name: 'dash.invoices.update',
            meta: { auth: true, routeIdentifier: 'invoices', can: permissions.CAN_VIEW_INVOICES, title: 'Update Invoice' },
            component: () => import(/* webpackChunkName: "invoices" */ '../views/Invoices/Upsert.vue')
          }
        ]
      }, {
        path: 'orders',
        component: () => import(/* webpackChunkName: "orders" */ '../views/Orders/Wrapper.vue'),
        children: [
          {
            path: '',
            name: 'dash.orders.list',
            meta: { auth: true, routeIdentifier: 'orders', can: permissions.CAN_VIEW_ORDERS, title: 'Orders' },
            component: () => import(/* webpackChunkName: "orders" */ '../views/Orders/List.vue')
          }, {
            path: 'calendar',
            name: 'dash.orders.calendar',
            meta: { auth: true, routeIdentifier: 'order-calendar', can: permissions.CAN_VIEW_ORDERS, title: 'Sales Calendar' },
            component: () => import(/* webpackChunkName: "orders" */ '../views/Orders/SalesCalendar.vue')
          }, {
            path: 'map',
            name: 'dash.orders.map',
            meta: { auth: true, routeIdentifier: 'order-map', can: permissions.CAN_VIEW_ORDERS, title: 'Sales Map' },
            component: () => import(/* webpackChunkName: "orders" */ '../views/Orders/SalesMap.vue')
          }, {
            path: 'create',
            name: 'dash.orders.create',
            meta: { auth: true, routeIdentifier: 'orders', can: permissions.CAN_VIEW_ORDERS, title: 'Create Order' },
            component: () => import(/* webpackChunkName: "orders" */ '../views/Orders/Upsert.vue')
          }, {
            path: 'finance',
            name: 'dash.orders.finance',
            meta: { auth: true, routeIdentifier: 'finance-orders', can: permissions.CAN_VIEW_ORDERS, title: 'Finance' },
            component: () => import(/* webpackChunkName: "orders" */ '../views/Orders/Finance.vue')
          }, {
            path: 'unsigned',
            name: 'dash.orders.unsigned',
            meta: { auth: true, routeIdentifier: 'unsigned-orders', can: permissions.CAN_VIEW_ORDERS, title: 'Unsigned' },
            component: () => import(/* webpackChunkName: "orders" */ '../views/Orders/Unsigned.vue')
          }, {
            path: ':id',
            name: 'dash.orders.update',
            meta: { auth: true, routeIdentifier: 'orders', can: permissions.CAN_VIEW_ORDERS, title: 'Update Order' },
            component: () => import(/* webpackChunkName: "orders" */ '../views/Orders/Upsert.vue')
          }, {
            path: 'view/:id',
            name: 'dash.orders.view',
            meta: { auth: true, routeIdentifier: 'orders', can: permissions.CAN_VIEW_ORDERS, title: 'View Order' },
            component: () => import(/* webpackChunkName: "orders" */ '../views/Orders/View.vue')
          }
        ]
      }, {
        path: 'reports',
        component: () => import(/* webpackChunkName: "orders" */ '../views/Orders/Wrapper.vue'),
        children: [
          {
            path: '',
            name: 'dash.reports',
            meta: { auth: true, routeIdentifier: 'reports', can: permissions.CAN_VIEW_REPORTS, title: 'Reports' },
            component: () => import(/* webpackChunkName: "orders" */ '../views/Reports/Reports.vue')
          }
        ]
      }, {
        path: 'reviews',
        component: () => import(/* webpackChunkName: "orders" */ '../views/Reviews/Wrapper.vue'),
        children: [
          {
            path: '',
            name: 'dash.reviews.list',
            meta: { auth: true, routeIdentifier: 'reviews', can: permissions.CAN_VIEW_REVIEWS, title: 'Reviews' },
            component: () => import(/* webpackChunkName: "orders" */ '../views/Reviews/List.vue')
          }
        ]
      }, {
        path: 'products',
        component: () => import(/* webpackChunkName: "products" */ '../views/Products/Wrapper.vue'),
        children: [
          {
            path: '',
            name: 'dash.products.list',
            meta: { auth: true, routeIdentifier: 'products', can: permissions.CAN_VIEW_PRODUCTS, title: 'Products' },
            component: () => import(/* webpackChunkName: "products" */ '../views/Products/List.vue')
          }, {
            path: 'create',
            name: 'dash.products.create',
            meta: { auth: true, routeIdentifier: 'products', can: permissions.CAN_VIEW_PRODUCTS, title: 'Create Product' },
            component: () => import(/* webpackChunkName: "products" */ '../views/Products/Upsert.vue')
          }, {
            path: ':id',
            name: 'dash.products.update',
            meta: { auth: true, routeIdentifier: 'products', can: permissions.CAN_VIEW_PRODUCTS, title: 'Update Product' },
            component: () => import(/* webpackChunkName: "products" */ '../views/Products/Upsert.vue')
          }
        ]
      }, {
        path: 'extras',
        component: () => import(/* webpackChunkName: "extras" */ '../views/Extras/Wrapper.vue'),
        children: [
          {
            path: '',
            name: 'dash.extras.list',
            meta: { auth: true, routeIdentifier: 'extras', can: permissions.CAN_VIEW_PRODUCTS, title: 'Extras' },
            component: () => import(/* webpackChunkName: "extras" */ '../views/Extras/List.vue')
          }, {
            path: 'create',
            name: 'dash.extras.create',
            meta: { auth: true, routeIdentifier: 'extras', can: permissions.CAN_VIEW_PRODUCTS, title: 'Create Extra' },
            component: () => import(/* webpackChunkName: "extras" */ '../views/Extras/Upsert.vue')
          }, {
            path: ':id',
            name: 'dash.extras.update',
            meta: { auth: true, routeIdentifier: 'extras', can: permissions.CAN_VIEW_PRODUCTS, title: 'Update Extra' },
            component: () => import(/* webpackChunkName: "extras" */ '../views/Extras/Upsert.vue')
          }
        ]
      }, {
        path: 'supplies',
        component: () => import(/* webpackChunkName: "supplies" */ '../views/Supplies/Wrapper.vue'),
        children: [
          {
            path: '',
            name: 'dash.supplies.list',
            meta: { auth: true, routeIdentifier: 'supplies', can: permissions.CAN_VIEW_SUPPLIES, title: 'Supplies' },
            component: () => import(/* webpackChunkName: "supplies" */ '../views/Supplies/List.vue')
          }, {
            path: 'create',
            name: 'dash.supplies.create',
            meta: { auth: true, routeIdentifier: 'supplies', can: permissions.CAN_VIEW_SUPPLIES, title: 'Create Supply' },
            component: () => import(/* webpackChunkName: "supplies" */ '../views/Supplies/Upsert.vue')
          }, {
            path: 'awaiting-approval',
            name: 'dash.supplies.awaiting-approval',
            meta: { auth: true, routeIdentifier: 'awaiting-approval', can: permissions.CAN_VIEW_SUPPLIES, title: 'Stock Awaiting Approval' },
            component: () => import(/* webpackChunkName: "supplies" */ '../views/Supplies/AwaitingApproval.vue')
          }, {
            path: 'awaiting-approval/:id',
            name: 'dash.supplies.awaiting-approval-view',
            meta: { auth: true, routeIdentifier: 'awaiting-approval', can: permissions.CAN_VIEW_SUPPLIES, title: 'Stock Awaiting Approval' },
            component: () => import(/* webpackChunkName: "supplies" */ '../views/Supplies/ViewUnapprovedSupply.vue')
          }, {
            path: ':id',
            name: 'dash.supplies.update',
            meta: { auth: true, routeIdentifier: 'supplies', can: permissions.CAN_VIEW_SUPPLIES, title: 'Update Supply' },
            component: () => import(/* webpackChunkName: "supplies" */ '../views/Supplies/Upsert.vue')
          }, {
            path: 'view/:id',
            name: 'dash.supplies.view',
            meta: { auth: true, routeIdentifier: 'supplies', can: permissions.CAN_VIEW_SUPPLIES, title: 'View Supply' },
            component: () => import(/* webpackChunkName: "supplies" */ '../views/Supplies/SupplyView.vue')
          }, {
            path: 'mark-arrivals/:id',
            name: 'dash.supplies.mark-arrivals',
            meta: { auth: true, routeIdentifier: 'supplies', can: permissions.CAN_VIEW_SUPPLIES, title: 'Mark Stock Arrival' },
            component: () => import(/* webpackChunkName: "supplies" */ '../views/Supplies/MarkSupplyProductArrival.vue')
          }
        ]
      }, {
        path: 'stock',
        component: () => import(/* webpackChunkName: "supplies" */ '../views/Stock/Wrapper.vue'),
        children: [
          {
            path: '',
            name: 'dash.stock.table',
            meta: { auth: true, routeIdentifier: 'stock', can: permissions.CAN_VIEW_SUPPLIES, title: 'Stock' },
            component: () => import(/* webpackChunkName: "supplies" */ '../views/Stock/Table.vue')
          }
        ]
      }, {
        path: 'users',
        component: () => import(/* webpackChunkName: "users" */ '../views/Users/Wrapper.vue'),
        children: [
          {
            path: '',
            name: 'dash.users.list',
            meta: { auth: true, routeIdentifier: 'users', can: permissions.CAN_VIEW_USERS, title: 'Users' },
            component: () => import(/* webpackChunkName: "users" */ '../views/Users/List.vue')
          }, {
            path: 'create',
            name: 'dash.users.create',
            meta: { auth: true, routeIdentifier: 'users', can: permissions.CAN_VIEW_USERS, title: 'Create User' },
            component: () => import(/* webpackChunkName: "users" */ '../views/Users/Upsert.vue')
          }, {
            path: ':id',
            name: 'dash.users.update',
            meta: { auth: true, routeIdentifier: 'users', can: permissions.CAN_VIEW_USERS, title: 'Update User' },
            component: () => import(/* webpackChunkName: "users" */ '../views/Users/Upsert.vue')
          }
        ]
      }, {
        path: 'timesheets',
        component: () => import(/* webpackChunkName: "users" */ '../views/Timesheets/Wrapper.vue'),
        children: [
          {
            path: '',
            name: 'dash.timesheets.list',
            meta: { auth: true, routeIdentifier: 'timesheets', can: permissions.CAN_VIEW_TIMESHEETS, title: 'Timesheets' },
            component: () => import(/* webpackChunkName: "users" */ '../views/Timesheets/Timesheet.vue')
          },
        ]
      }, {
        path: 'vans',
        component: () => import(/* webpackChunkName: "users" */ '../views/Vans/Wrapper.vue'),
        children: [
          {
            path: '',
            name: 'dash.vans.list',
            meta: { auth: true, routeIdentifier: 'vans', can: permissions.CAN_VIEW_VANS, title: 'Vans' },
            component: () => import(/* webpackChunkName: "users" */ '../views/Vans/Vans.vue')
          },
          {
            path: ':id',
            name: 'dash.vans.upsert',
            meta: { auth: true, routeIdentifier: 'vans', can: permissions.CAN_VIEW_VANS, title: 'Vans' },
            component: () => import(/* webpackChunkName: "users" */ '../views/Vans/Upsert.vue')
          },
        ]
      }, {
        path: 'security',
        component: () => import(/* webpackChunkName: "users" */ '../views/Security/Wrapper.vue'),
        children: [
          {
            path: '',
            name: 'dash.security.manage',
            meta: { auth: true, routeIdentifier: 'security', title: 'Security' },
            component: () => import(/* webpackChunkName: "users" */ '../views/Security/Security.vue')
          }
        ]
      }, {
        path: 'reminders',
        component: () => import(/* webpackChunkName: "users" */ '../views/Reminders/Wrapper.vue'),
        children: [
          {
            path: '',
            name: 'dash.reminders.list',
            meta: { auth: true, routeIdentifier: 'reminders', can: permissions.CAN_VIEW_CUSTOMERS, title: 'Reminders' },
            component: () => import(/* webpackChunkName: "users" */ '../views/Reminders/List.vue')
          },
          {
            path: 'create',
            name: 'dash.reminders.create',
            meta: { auth: true, routeIdentifier: 'reminders', can: permissions.CAN_VIEW_CUSTOMERS, title: 'Create Reminder' },
            component: () => import(/* webpackChunkName: "users" */ '../views/Reminders/Upsert.vue')
          },
          {
            path: 'update',
            name: 'dash.reminders.update',
            meta: { auth: true, routeIdentifier: 'reminders', can: permissions.CAN_VIEW_CUSTOMERS, title: 'Update Reminder' },
            component: () => import(/* webpackChunkName: "users" */ '../views/Reminders/Upsert.vue')
          },
        ]
      }
    ]
  }, {
    path: '/auth',
    component: () => import(/* webpackChunkName: "auth" */ '../views/Authentication/Container.vue'),
    children: [
      {
        path: 'not-allowed',
        name: 'auth.not-allowed',
        meta: { auth: true, title: 'Not Allowed' },
        component: () => import(/* webpackChunkName: "auth" */ '../views/Authentication/NotAllowed.vue')
      }, {
        path: 'login',
        name: 'auth.login',
        meta: { auth: false, title: 'Log In' },
        component: () => import(/* webpackChunkName: "auth" */ '../views/Authentication/Login.vue')
      }, {
        path: 'logout',
        name: 'auth.logout',
        meta: { auth: true, title: 'Log Out' },
        component: () => import(/* webpackChunkName: "auth" */ '../views/Authentication/Logout.vue')
      }
    ]
  }, {
    path: '/track-order',
    component: () => import(/* webpackChunkName: "auth" */ '../views/OrderTracking/Container.vue'),
    children: [
      {
        path: ':id/:trackingPath',
        name: 'track-order',
        meta: { auth: false, title: 'Track Order' },
        component: () => import(/* webpackChunkName: "auth" */ '../views/OrderTracking/TrackOrder.vue')
      }
    ]
  }, {
    path: '/submit-video',
    component: () => import(/* webpackChunkName: "auth" */ '../views/OrderTracking/Container.vue'),
    children: [
      {
        path: ':id/:email',
        name: 'submit-video',
        meta: { auth: false, title: 'Submit Video' },
        component: () => import(/* webpackChunkName: "auth" */ '../views/SubmitVideo/SubmitVideo.vue')
      }
    ]
  }, {
    path: '/payment',
    component: () => import(/* webpackChunkName: "auth" */ '../views/OrderTracking/Container.vue'),
    children: [
      {
        path: 'complete/:id',
        name: 'payment-complete',
        meta: { auth: false, title: 'Payment Complete' },
        component: () => import(/* webpackChunkName: "auth" */ '../views/PaymentComplete/PaymentComplete.vue')
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

const permsKey = 'authentication/userPermissions'
router.beforeEach((to, from, next) => {

  var roleRedirections = {
    'Admin': 'dash.index',
    'Sales': 'dash.index',
    'Installer': 'dash.installations',
    'Support': 'dash.tickets.list'
  };

  /**
   * Clear Validation Errors.
   */
  Object.keys(store.state).forEach(module => {
    let exemptModules = ['authentication', 'calls', 'overview', 'storage-locations', 'vans', 'emails'];
    if(!exemptModules.includes(module)) {
      store.dispatch(`${module}/clearErrors`)
    }
  })

  /**
   * Authentication
   */

  var passableUrls = [
    '/track-order/:id/:trackingPath',
    '/submit-video/:id/:email',
    '/payment/complete/:id'
  ];

  if(to.matched.some( r => passableUrls.includes(r.path) )) {
    return next()
  }

  if (to.matched.some(r => r.meta.auth)) {
    if (from.matched.some(r => r.meta.auth)) {
      return next()
    }

    store.dispatch('authentication/check')
      .then(response => {
        // Everyone has security auth.
        if(to.matched.some( r => ['/security'].includes(r.path) )) {
          return next()
        }

        if (to.matched.some(r => r.meta.can)) {
          if (includes(store.getters[permsKey], to.meta.can)) {
            return next()
          }else{
            if(Object.keys(roleRedirections).includes(response.data.roles[0].name)) {
              if(to.name != roleRedirections[ response.data.roles[0].name]) {
                return next({ name: roleRedirections[ response.data.roles[0].name ] })
              }
            }
          }
          return next({ name: 'auth.not-allowed' })
        }else{
          if(Object.keys(roleRedirections).includes(response.data.roles[0].name)) {
            if(to.name != roleRedirections[ response.data.roles[0].name]) {
              return next({ name: roleRedirections[ response.data.roles[0].name ] })
            }
          }
        }
        return next()
      })
      .catch((err) => next({ name: 'auth.login', query: { next: from.path } }))
  } else if (to.matched.some(r => !r.meta.auth)) {
    if (from.matched.some(r => !r.meta.auth)) {
      return next()
    } else {
      store.dispatch('authentication/check')
        .then(() => next({ name: 'dash.index' }))
        .catch(() => next())
    }
  } else {
    next()
  }
})

export default router