<template>
  <b-col :class="{'d-flex': showSidebar, 'd-none': !showSidebar}" class="bg-light d-none d-md-block sidebar" cols="12" md="3" xl="2">
    <div class="mh-100 sidebar-scroll">
      <b-nav class="mb-4" vertical v-if="hasAnyPermission(['overview'])">
        <b-nav-text class="nav-header">Overview</b-nav-text>
        <b-nav-item class="text-dark" v-if="hasAnyPermission(['overview'])" to="/" :active="routeIdentifierActive('overview')"><b-icon class="mr-2" font-scale="1.4" icon="house" /> Overview</b-nav-item>
      </b-nav>
      <b-nav class="mb-4" vertical v-if="hasAnyPermission(['customers', 'groups', 'calls'])">
        <b-nav-text class="nav-header">Customers</b-nav-text>
        <b-nav-item class="text-dark" v-if="hasAnyPermission(['customers'])" to="/customers/mine" :active="routeIdentifierActive('my-customers')"><b-icon class="mr-2" font-scale="1.4" icon="person-circle" /> My Customers</b-nav-item>
        <b-nav-item class="text-dark" v-if="hasAnyPermission(['customers'])" to="/customers/unassigned" :active="routeIdentifierActive('unassigned-customers')"><b-icon class="mr-2" font-scale="1.4" icon="circle" /> Unassigned Customers</b-nav-item>
        <b-nav-item class="text-dark" v-if="hasAnyPermission(['customers'])" to="/customers" :active="routeIdentifierActive('customers')"><b-icon class="mr-2" font-scale="1.4" icon="people" /> All Customers</b-nav-item>

        <b-nav-item class="text-dark" to="/reminders" v-if="hasAnyPermission(['customers'])" :active="routeIdentifierActive('reminders')"><b-icon class="mr-2" font-scaleß="1.4" icon="list-task" /> Reminders</b-nav-item>
        <b-nav-item class="text-dark" v-if="hasAnyPermission(['calls'])" to="/calls" :active="routeIdentifierActive('calls')"><b-icon class="mr-2" font-scale="1.4" icon="telephone" /> Calls</b-nav-item>
        <b-nav-item class="text-dark" v-if="hasAnyPermission(['calls'])" to="/calls/missed" :active="routeIdentifierActive('missed-calls')"><b-icon class="mr-2" font-scale="1.4" icon="telephone-x" /> Missed Calls</b-nav-item>
        <b-nav-item class="text-dark" v-if="hasAnyPermission(['calls'])" to="/calls/scheduled" :active="routeIdentifierActive('scheduled-calls')"><b-icon class="mr-2" font-scale="1.4" icon="telephone-outbound" /> Scheduled Calls</b-nav-item>
      </b-nav>
      <b-nav class="mb-4" vertical v-if="hasAnyPermission(['customers'])">
        <b-nav-text class="nav-header">Emails</b-nav-text>
        <b-nav-item class="text-dark" v-if="hasAnyPermission(['customers'])" to="/emails" :active="routeIdentifierActive('emails')"><b-icon class="mr-2" font-scale="1.4" icon="inbox" /> Email Feed</b-nav-item>
        <b-nav-item class="text-dark" v-if="hasAnyPermission(['customers'])" to="/templates" :active="routeIdentifierActive('templates')"><b-icon class="mr-2" font-scale="1.4" icon="envelope" /> Templates</b-nav-item>
      </b-nav>
      <b-nav class="mb-4" vertical v-if="hasAnyPermission(['invoices', 'orders'])">
        <b-nav-text class="nav-header">Orders & Payments</b-nav-text>
        <b-nav-item class="text-dark" v-if="hasAnyPermission(['orders'])" to="/orders" :active="routeIdentifierActive('orders')"><b-icon class="mr-2" font-scale="1.4" icon="cart-4" /> Orders</b-nav-item>
        <b-nav-item class="text-dark" v-if="hasAnyPermission(['orders'])" to="/orders/unsigned" :active="routeIdentifierActive('unsigned-orders')"><b-icon class="mr-2" font-scale="1.4" icon="pencil-square" /> Unsigned</b-nav-item>
        <b-nav-item class="text-dark" v-if="hasAnyPermission(['invoices'])" to="/invoices/soon" :active="routeIdentifierActive('delivering-soon')"><b-icon class="mr-2" font-scale="1.4" icon="exclamation-diamond" /> Delivering Soon</b-nav-item>
        <b-nav-item class="text-dark" v-if="hasAnyPermission(['invoices'])" to="/invoices" :active="routeIdentifierActive('invoices')"><b-icon class="mr-2" font-scale="1.4" icon="credit-card" /> Invoices</b-nav-item>
        <b-nav-item class="text-dark" v-if="hasAnyPermission(['invoices'])" to="/invoices/recent" :active="routeIdentifierActive('recent-payments')"><b-icon class="mr-2" font-scale="1.4" icon="cash-stack" /> Recent Payments</b-nav-item>
        <b-nav-item class="text-dark" v-if="hasAnyPermission(['orders'])" to="/orders/finance" :active="routeIdentifierActive('finance-orders')"><b-icon class="mr-2" font-scale="1.4" icon="check2-circle" /> Finance</b-nav-item>
        <b-nav-item class="text-dark" v-if="hasAnyPermission(['orders'])" to="/orders/calendar" :active="routeIdentifierActive('order-calendar')"><b-icon class="mr-2" font-scale="1.4" icon="calendar2-date" /> Sales Calendar</b-nav-item>
        <b-nav-item class="text-dark" v-if="hasAnyPermission(['orders'])" to="/orders/map" :active="routeIdentifierActive('order-map')"><b-icon class="mr-2" font-scale="1.4" icon="geo-alt" /> Sales Map</b-nav-item>
      </b-nav>
      <b-nav class="mb-4" vertical v-if="hasAnyPermission(['assigned-installations', 'all-installations', 'timesheets', 'teams', 'supplies', 'vans'])">
        <b-nav-text class="nav-header">Delivery / Installation</b-nav-text>
        <b-nav-item class="text-dark" v-if="hasAnyPermission(['assigned-installations'])" to="/installations" :active="routeIdentifierActive('installations')"><b-icon class="mr-2" font-scale="1.4" icon="truck" /> Installs</b-nav-item>
        <b-nav-item class="text-dark" v-if="hasAnyPermission(['all-installations'])" to="/installations/calendar" :active="routeIdentifierActive('installation-calendar')"><b-icon class="mr-2" font-scale="1.4" icon="calendar2-date" /> Installation Calendar</b-nav-item>
        <b-nav-item class="text-dark" v-if="hasAnyPermission(['all-installations'])" to="/installations/manage" :active="routeIdentifierActive('installation-manager')"><b-icon class="mr-2" font-scale="1.4" icon="bounding-box-circles" /> Install Manager</b-nav-item>
        <b-nav-item class="text-dark" v-if="hasAnyPermission(['all-installations'])" to="/installations/routes" :active="routeIdentifierActive('installation-routes')"><b-icon class="mr-2" font-scale="1.4" icon="camera-video" /> Installation Routes</b-nav-item>
        <b-nav-item class="text-dark" v-if="hasAnyPermission(['all-installations'])" to="/installations/routes/pending" :active="routeIdentifierActive('installation-routes-pending')"><b-icon class="mr-2" font-scale="1.4" icon="camera-video-off" /> Pending Installation Routes</b-nav-item>
        <b-nav-item class="text-dark" v-if="hasAnyPermission(['all-installations'])" to="/installations/sign-offs" :active="routeIdentifierActive('installation-sign-offs')"><b-icon class="mr-2" font-scale="1.4" icon="check" /> Installation Sign Offs</b-nav-item>
        <b-nav-item class="text-dark" v-if="hasAnyPermission(['timesheets'])" to="/timesheets" :active="routeIdentifierActive('timesheets')"><b-icon class="mr-2" font-scale="1.4" icon="clock-history" /> Timesheets</b-nav-item>
        <b-nav-item class="text-dark" v-if="hasAnyPermission(['teams'])" to="/teams" :active="routeIdentifierActive('teams')"><b-icon class="mr-2" font-scale="1.4" icon="people" /> Teams</b-nav-item>
        <b-nav-item class="text-dark" v-if="hasAnyPermission(['supplies'])" to="/supplies/awaiting-approval" :active="routeIdentifierActive('awaiting-approval')"><b-icon class="mr-2" font-scale="1.4" icon="box" /> Stock Approval</b-nav-item>
        <b-nav-item class="text-dark" v-if="hasAnyPermission(['supplies'])" to="/supplies" :active="routeIdentifierActive('supplies')"><b-icon class="mr-2" font-scale="1.4" icon="box-seam" /> Supplies</b-nav-item>
        <b-nav-item class="text-dark" v-if="hasAnyPermission(['supplies'])" to="/stock" :active="routeIdentifierActive('stock')"><b-icon class="mr-2" font-scale="1.4" icon="clipboard-check" /> Stock</b-nav-item>
        <b-nav-item class="text-dark" v-if="hasAnyPermission(['vans'])" to="/vans" :active="routeIdentifierActive('vans')"><b-icon class="mr-2" font-scale="1.4" icon="cursor" /> Vans</b-nav-item>
      </b-nav>
      <b-nav class="mb-4" vertical v-if="hasAnyPermission(['support-tickets', 'repairs', 'reviews'])">
        <b-nav-text class="nav-header">Aftercare</b-nav-text>
        <b-nav-item class="text-dark" v-if="hasAnyPermission(['support-tickets'])" to="/tickets" :active="routeIdentifierActive('support')"><b-icon class="mr-2" font-scale="1.4" icon="chat-left-text" /> Support</b-nav-item>
        <b-nav-item class="text-dark" v-if="hasAnyPermission(['repairs'])" to="/repairs" :active="routeIdentifierActive('repairs')"><b-icon class="mr-2" font-scale="1.4" icon="tools" /> Repairs</b-nav-item>
        <b-nav-item class="text-dark" v-if="hasAnyPermission(['repairs'])" to="/repairs/calendar" :active="routeIdentifierActive('repair-calendar')"><b-icon class="mr-2" font-scale="1.4" icon="calendar2-date" /> Repair Calendar</b-nav-item>
        <b-nav-item class="text-dark" v-if="hasAnyPermission(['reviews'])" to="/reviews" :active="routeIdentifierActive('reviews')"><b-icon class="mr-2" font-scale="1.4" icon="bar-chart" /> Reviews</b-nav-item>
      </b-nav>
      <b-nav class="mb-4" vertical v-if="hasAnyPermission(['products'])">
        <b-nav-text class="nav-header">Products</b-nav-text>
        <b-nav-item class="text-dark" v-if="hasAnyPermission(['products'])" to="/products" :active="routeIdentifierActive('products')"><b-icon class="mr-2" font-scale="1.4" icon="collection" /> Products</b-nav-item>
        <b-nav-item class="text-dark" v-if="hasAnyPermission(['products'])" to="/extras" :active="routeIdentifierActive('extras')"><b-icon class="mr-2" font-scale="1.4" icon="bag-plus" /> Extras</b-nav-item>
      </b-nav>
      <b-nav class="mb-4" vertical v-if="hasAnyPermission(['reports'])">
        <b-nav-text class="nav-header">Reporting</b-nav-text>
        <b-nav-item class="text-dark" v-if="hasAnyPermission(['reports'])" to="/reports" :active="routeIdentifierActive('reports')"><b-icon class="mr-2" font-scale="1.4" icon="file-text" /> Reports</b-nav-item>
      </b-nav>
      <b-nav class="mb-4" vertical>
        <b-nav-text class="nav-header">Administration</b-nav-text>
        <b-nav-item class="text-dark" v-if="hasAnyPermission(['users'])" to="/users" :active="routeIdentifierActive('users')"><b-icon class="mr-2" font-scale="1.4" icon="person-check" /> Users</b-nav-item>
        <b-nav-item class="text-dark" v-if="hasAnyPermission(['groups'])" to="/groups" :active="routeIdentifierActive('groups')"><b-icon class="mr-2" font-scale="1.4" icon="wallet" /> Groups</b-nav-item>
        <b-nav-item class="text-dark" to="/security" :active="routeIdentifierActive('security')"><b-icon class="mr-2" font-scale="1.4" icon="shield-shaded" /> Security</b-nav-item>
      </b-nav>
    </div>
  </b-col>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  data () {
    return { showSidebar: false }
  },
  computed: {
    ...mapGetters('authentication', ['userPermissions'])
  },
  methods: {
    hasAnyPermission(permissions) {
      let hasAnyPermission = false;
      if(Array.isArray(permissions)) {
        permissions.forEach(function(permission) {
          if(this.userPermissions.includes(permission)) hasAnyPermission = true;
        }, this)
      }
      return hasAnyPermission;
    },
    routeIdentifierActive(routeIdentifier) {
      if(!this.$route.meta.hasOwnProperty('routeIdentifier')) return false
      return (routeIdentifier == this.$route.meta.routeIdentifier)
    }
  },
  mounted () {
    this.$root.$on('sidebar::toggle', () => {
      this.showSidebar = !this.showSidebar
    })
    this.$root.$on('sidebar::set', (value) => {
      this.showSidebar = value
    })
  },
}
</script>

<style lang="scss" scoped>
  .sidebar {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    padding: 68px 0 0;
    z-index: 100;
    border-right: 1px solid darken($light, 10%);

    .sidebar-scroll {
      overflow-y: scroll;
      width: 100%;
    }

    ul.nav {
      li.nav-header {
        color: lighten($dark, 20%);
        font-size: 0.8rem;
        font-weight: bold;
        padding: 0.5rem 1rem;
        text-transform: uppercase;
      }

      li.nav-item {
        text-overflow: ellipsis;
        width: 100%;

        a {
          color: $dark;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 100%;

          svg {
            color: lighten($dark, 10%);
            margin-top: -.2em;
          }

          &:not(.active):hover {
            background: darken($light, 5%);
          }

          &.active {
            background: $primary;
            color: $light;

            svg {
              color: $light;
            }
          }
        }
      }
    }
  }
  
  @media (max-width: 765px) {
    .sidebar {
      border-right: 0;
    }
  }
</style>
