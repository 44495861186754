import { mapGetters } from 'vuex'
import cookie from 'cookie'

import AxiosDigestAuth from '@mhoc/axios-digest-auth'
export default {
  data: () => ({
    connected: false,
  }),
  computed: {
    ...mapGetters('authentication', ['isLoading', 'xsrfTokenLoaded', 'currentUser']),
  },
  watch: {
    currentUser(newUser, oldUser) {
      if(newUser.voip_extension && newUser.voip_ip && newUser.voip_password) {
        if(this.connected) {
          this.disconnectListener().then(() => { this.connectListener() })
        }else{
          this.connectListener()
        }
      }else{
        this.disconnectListener()
      }
    }
  },
  mounted() {
    this.$root.$on('answer-call', payload => {
      const digestAuth = new AxiosDigestAuth({
        username: `${this.currentUser.voip_extension}`,
        password: `${this.currentUser.voip_password}`,
      });
      digestAuth.request({
        method: 'GET',
        url: `https://192.168.1.11/command.htm?key=SPEAKER` // Or HEADSET?
      }).then(response => {
        console.log({ response })
      }).catch(error => {
        console.log({ error })
      })
    })

    this.$root.$on('end-call', payload => {
      const digestAuth = new AxiosDigestAuth({
        username: `${this.currentUser.voip_extension}`,
        password: `${this.currentUser.voip_password}`,
      });
      digestAuth.request({
        method: 'GET',
        url: `https://192.168.1.11/command.htm?key=CANCEL`
      })
    })

    this.$root.$on('initiate-call', number => {
      const digestAuth = new AxiosDigestAuth({
        username: `${this.currentUser.voip_extension}`,
        password: `${this.currentUser.voip_password}`,
      });
      digestAuth.request({
        method: 'GET',
        url: `https://${this.currentUser.voip_ip}/command.htm?number=${number}`
      })
    })
  },
  methods: {
    connectListener() {
      return new Promise((resolve, reject) => {
        window.Echo.connector.pusher.config.auth.headers['X-XSRF-TOKEN'] = cookie.parse(document.cookie)['XSRF-TOKEN']
        
        window.Echo.private(`snom-channel.${this.currentUser.id}`)
        /**
         * Snom Call Incoming
         */
          .listen('SnomCallIncoming', call => {
            console.log({'incoming': call})
            this.$root.$emit('card-notification', {
              variant: 'success',
              title: `Incoming Call`,
              subtitle: call.customer_id ? `Call incoming with ${call.customer.forename} ${call.customer.surname}` : 'Call incoming from unknown',
              type: 'call',
              status: 'incoming',
              direction: call.direction,
              meta: call
            })
          })
          .listen('SnomCallConnected', call => {
            console.log({'connected': call})
            this.$root.$emit('call-connected', call)
          })
          .listen('SnomCallDisconnected', call => {
            console.log({'disconnected': call})
            this.$root.$emit('call-disconnected', call)
          })
          .listen('SnomCallOutgoing', call => {
            console.log({'outgoing': call})
            this.$root.$emit('card-notification', {
              variant: 'success',
              title: `Outgoing Call`,
              subtitle: call.customer_id ? `Outgoing call with ${call.customer.forename} ${call.customer.surname}` : 'Outgoing call with unknown',
              status: 'outgoing',
              direction: call.direction,
              type: 'call',
              meta: call
            })
          })
        
        this.connected = true
        resolve()
      })
    },

    disconnectListener() {
      return new Promise((resolve, reject) => {
        window.Echo.leave('snom-channel')
        this.connected = false
        resolve()
      })
    }
  }
}
