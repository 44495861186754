<template>
  <div>
    <span v-b-popover.hover.top="fullDate" v-if="direction == 'top'">{{ fromNow }}</span>
    <span v-b-popover.hover.left="fullDate" v-if="direction == 'left'">{{ fromNow }}</span>
    <span v-b-popover.hover.right="fullDate" v-if="direction == 'right'">{{ fromNow }}</span>
    <span v-b-popover.hover.bottom="fullDate" v-if="direction == 'bottom'">{{ fromNow }}</span>
  </div>
</template>

<script>
import momentMixin from '../mixins/momentMixin'

export default {
  mixins: [momentMixin],
  computed: {
    fromNow () {
      return this.moment(this.date).fromNow()
    },
    fullDate () {
      return this.moment(this.date).format(`Do MMMM YYYY${!this.omitTime ? ' HH:mm:ss' : ''}`)
    }
  },
  props: {
    date: {
      required: true,
      type: String
    },
    omitTime: {
      default: false,
      type: Boolean,
    },
    direction: {
      default: 'top',
      type: String
    }
  }
}
</script>

<style>

</style>
