<template>
  <div id="app">
    <CardNotifications>
      <transition name="fade" mode="out-in">
        <div class="align-items-center bg-primary d-flex flex-column justify-content-center vh-100" v-if="isLoading && !loaded">
          <b-spinner variant="light"></b-spinner>
        </div>
        <router-view v-if="!isLoading || loaded" />
      </transition>
    </CardNotifications>
  </div>
</template>

<script>
import CardNotifications from './components/CardNotifications'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    CardNotifications
  },
  created () {
    this.token()
  },
  data () {
    return {
      loaded: false
    }
  },
  computed: {
    ...mapGetters('authentication', ['isLoading', 'xsrfTokenLoaded'])
  },
  methods: {
    ...mapActions('authentication', ['token'])
  },
  watch: {
    '$route' (val) {
      document.title = val.meta.title ? `${val.meta.title} | Better Living Outdoors` : 'Better Living Outdoors'
    },
    isLoading (val) {
      if (val) this.loaded = true
    }
  }
}
</script>

<style lang="scss">
  $link-color: $secondary;
  @import "bootstrap";
  @import "~bootstrap-vue/dist/bootstrap-vue.css";

  body {
    background: lighten($light, 2.5%);
  }

  .back-button {
    margin-left: -100%;
    position: relative;
  }

  .modal-backdrop {
    background: $primary;
    opacity: 0.8;
  }

  .fade-enter-active, .fade-leave-active {
    transition: opacity 250ms;
  }
  .fade-enter, .fade-leave-to {
    opacity: 0;
  }
  .w-100-m-1 {
    width: calc(100% - 1rem);
  }
  .cursor-pointer {
    cursor: pointer;
  }

  .white-space-pre {
    white-space: pre-wrap;
  }

  .text-break {
    word-break: break-word!important;
    overflow-wrap: break-word!important;
  }

  .fc th {
    padding: 5px !important;
  }
  
  :root {
    --fc-today-bg-color: #{$secondary};
    --fc-button-bg-color: #{$primary}
  }

  .fc-day-today .fc-daygrid-event {
    color: #fff;
  }

  @media screen and (max-width: 680px) {
    .fc .fc-toolbar {
      display: block !important;
    }
    .fc .fc-toolbar .fc-toolbar-chunk {
        width: 100%;
        display: block;
        margin-bottom: 0.75rem !important;
    }
  }
  
  
</style>
