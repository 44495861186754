<template>
  <div class="notifs">
    <slot></slot>
    <div class="d-none d-md-flex flex-column justify-content-end notifs-area p-4 vh-100">
      <div id="snom-http-iframe-container"></div>
      <transition-group name="fade" mode="out-in">
        <b-card v-for="(notification, $index) in sortedNotifications" :class="`border-${notification.variant || 'dark'} ${notification.dark ? 'bg-dark text-white' : ''}`" class="mt-4 shadow" :key="`notif-${$index}`">
          <b-card-title :class="{ 'mb-0': (notification.hasOwnProperty('type') && notification.type == 'call' && (['disconnected'].includes(notification.status && !notification.meta.customer_id))) }">
              <b-row>
                <b-col cols="7" class="d-flex flex-row flex-fill align-items-center"><p class="mb-0">{{ notification.title }}</p></b-col>
                <b-col cols="5" class="d-flex flex-row flex-fill justify-content-end">
                  <small class="text-muted" v-if="notification.hasOwnProperty('type') && notification.type == 'call' && ['incoming', 'outgoing', 'connected'].includes(notification.status)">{{ elapsedTime(notification) }}</small>
                </b-col>
              </b-row>
          </b-card-title>
          <b-card-sub-title v-if="notification.subtitle">
            <p>{{ notification.subtitle }}</p>
          </b-card-sub-title>
          <div class="d-flex flex-row">
            <div v-for="(button, $buttonIndex) in notification.buttonLayout" :class="{'mr-1': notification.buttonLayout.length - 1 !== $buttonIndex, 'flex-fill': !Array.isArray(button)}" :key="`notif-${index}-button-${$buttonIndex}`">
              <b-button block :variant="button.variant" v-if="!Array.isArray(button)" v-b-popover.hover.top="button.popover"><b-icon :class="{'mr-2': button.text}" :icon="button.icon" v-if="button.icon"></b-icon>{{ button.text }}</b-button>
              <div class="d-flex flex-row" v-if="Array.isArray(button)">
                <div v-for="(button, $buttonIndex) in button" :class="{'mr-1': notification.buttonLayout.length - 1 !== $buttonIndex, 'flex-fill': !Array.isArray(button)}" :key="`notif-${index}-button-${$buttonIndex}`">
                  <b-button block :variant="button.variant" v-if="!Array.isArray(button)" v-b-popover.hover.top="button.popover"><b-icon :class="{'mr-2': button.text}" :icon="button.icon" v-if="button.icon"></b-icon>{{ button.text }}</b-button>
                </div>
              </div>
            </div>
            <div v-if="notification.hasOwnProperty('type') && notification.type == 'call' && notification.status == 'incoming'">
              <b-button block variant="secondary" @click="answerCall(notification, $index)" v-b-popover.hover.top="'Answer call and redirect to customer view.'"><b-icon :class="'mr-2'" icon="telephone"></b-icon>Answer Call</b-button>
            </div>
            <div v-if="notification.hasOwnProperty('type') && notification.type == 'call' && ['connected'].includes(notification.status)">
              <b-button block variant="secondary" :disabled="alreadyOnRoute('dash.customers.view', notification.meta.customer_id)" :to="{ name: 'dash.customers.view', params: { id: notification.meta.customer_id } }" v-b-popover.hover.top="'View Customer.'" v-if="notification.meta.customer_id"><b-icon :class="'mr-2'" icon="person"></b-icon>View Customer</b-button>
              <b-button block variant="secondary" :disabled="alreadyOnRoute('dash.customers.create')" :to="{ name: 'dash.customers.create', params: { id: notification.meta.customer_id } }" v-b-popover.hover.top="'Create Customer.'" v-else><b-icon :class="'mr-2'" icon="person-plus"></b-icon>Create Customer</b-button>
            </div>
            <div v-if="notification.hasOwnProperty('type') && notification.type == 'call' && ['incoming', 'outgoing', 'connected'].includes(notification.status)" class="ml-2">
              <b-button block variant="danger" @click="endCall(notification, $index)" v-b-popover.hover.top="'End Call.'"><b-icon :class="'mr-2'" icon="telephone-x"></b-icon>End Call</b-button>
            </div>
          </div>
          <b-card-text v-if="notification.text">{{ notification.text }}</b-card-text>
        </b-card>
      </transition-group>
    </div>
  </div>
</template>

<script>
import orderBy from 'lodash/orderBy'
import snomMixin from '../mixins/snomMixin'
import momentMixin from '../mixins/momentMixin'
import FriendlyDate from '../components/FriendlyDate'

export default {
  mixins: [snomMixin, momentMixin],
  components: { FriendlyDate },
  computed: {
    sortedNotifications () {
      return orderBy(this.notifications, 'ts', 'desc')
    },
  },
  data () {
    return {
      notifications: [],
      currentTime: null,
    }
  },
  mounted() {
    // Continuous update of current time data in order to update notification elapsed time function.
    this.currentTime = this.moment()
    setInterval(() => { this.currentTime = this.moment() }, 1000)

    this.$root.$on('card-notification', notification => {
      // Check if call already exists:
      if(notification.hasOwnProperty('type') && notification.type == 'call') {
        var callNotificationIndex = this.notifications.findIndex(currentNotification => {
          return (currentNotification.hasOwnProperty('type') && currentNotification.type == 'call')
        })
        if(callNotificationIndex >= 0) {
          this.notifications[callNotificationIndex] = notification
        }else{
          this.notifications.push(notification)
        }
      }else{
        this.notifications.push(notification)
      }
      
      if(notification.timeout) {
        setTimeout(() => {
          this.notifications.splice(this.notifications.indexOf(notification), 1)
        }, notification.timeout ?? 2500)
      }
    })

    /**
     * Snom Call Connected
     */
    this.$root.$on('call-connected', call => {
      var callNotificationIndex = this.notifications.findIndex(notification => {
        if(notification.hasOwnProperty('type') && notification.type == 'call') {
          return (notification.meta.call_id == call.call_id);
        }
      })

      var notification = this.notifications[callNotificationIndex]
      notification.title = 'Ongoing Call'
      notification.subtitle = call.customer_id ? `${call.direction} call with ${call.customer.forename} ${call.customer.surname}` : `Connected with ${call.direction == 'incoming' ? call.caller : call.receiver}`
      notification.status = 'connected'
      notification.direction = call.direction
      notification.meta = call

    })

    /**
     * Snom Call Missed
     */

    /**
     * Snom Call Disconnected
     */
    this.$root.$on('call-disconnected', call => {
      var callNotificationIndex = this.notifications.findIndex(notification => {
        if(notification.hasOwnProperty('type') && notification.type == 'call') {
          return (notification.meta.call_id == call.call_id);
        }
      })

      if(callNotificationIndex >= 0) {
        var notification = this.notifications[callNotificationIndex]
        notification.title = 'Call Disconnected'
        notification.subtitle = call.customer_id ? `Call with ${call.customer.forename} ${call.customer.surname} disconnected.` : '',
        notification.status = 'disconnected'
        notification.meta = call

        window.axios.get(`/snom/disconnect-confirmation/${ call.id }`).then(response => {
          if(response.data) {
            setTimeout(() => {
              this.notifications.splice(callNotificationIndex, 1)
            }, 1000)
          }
        })
      }
    })

    /**
     * Snom Call Outgoing
     */
  },
  watch: {
    '$route': function(to, from) {
      (to.params.notifications ?? []).forEach((notification, index) => {
        this.$root.$emit('card-notification', notification)
      })
    }
  },
  methods: {
    answerCall(notification, ind) {
      this.$root.$emit('answer-call', notification);
    },

    endCall(notification, ind) {
      this.$root.$emit('end-call', notification);
    },

    alreadyOnRoute(name, customer) {
      return (this.$route.name == name && ( customer == undefined ) || ( this.$route.params.hasOwnProperty('id') && this.$route.params.id == customer ))
    },

    elapsedTime(notification) {
      switch(notification.status) {
        case 'incoming': return `${this.moment(notification.meta.created_at).format('hh:mm:ss')}`; break;
        case 'outgoing': var startTime = notification.meta.created_at; break;

        case 'connected':
          switch(notification.direction) {
            case 'incoming': var startTime = notification.meta.answered_at; break;
            case 'outgoing': var startTime = notification.meta.created_at; break;
          }
        break;

      }

      var momentStartTime = this.moment(startTime)

      var seconds = 0;
      var minutes = 0;
      var hours = 0;
      seconds = Math.floor(this.currentTime.diff(momentStartTime) / 1000)
      if(seconds > 60) {
        minutes = seconds / 60; seconds = Math.floor(seconds % 60);
        if(minutes > 60) {
          hours = minutes / 60; minutes = Math.floor(minutes % 60);
        }
      }

      var secondDisplay = (Math.floor(seconds) < 10) ? `0${Math.floor(seconds)}` : Math.floor(seconds);
      var minuteDisplay = (Math.floor(minutes) < 10) ? `0${Math.floor(minutes)}` : Math.floor(minutes);
      var hoursDisplay = hours ? (Math.floor(hours) < 10) ? `0${Math.floor(hours)}` : Math.floor(hours) : false;


      return hoursDisplay ? `${hoursDisplay}:${minuteDisplay}:${secondDisplay}` : `${minuteDisplay}:${secondDisplay}`;
    }
  }
}
</script>

<style lang="scss" scoped>
  .notifs {
    .notifs-area {
      position: fixed;
      top: 0; right: 0;
      pointer-events: none;
      max-width: 500px;
      z-index: 998 !important;
      width: 100%;
    }

    .card {
      pointer-events: initial;
      position: relative;

      &.bg-dark {
        h6.card-subtitle.text-muted.mb-2 {
          color: darken($light, 15%) !important;
        }
      }

      .close {
        cursor: pointer;
        position: absolute;
        top: .5rem; right: .5rem;
      }
    }
  }
</style>
